import { Injectable } from "@angular/core";
import { CatalogModel } from "../models/catalog.model";
import { CatalogService } from "../services/catalog.service";
import { retry } from "rxjs";

@Injectable({
    providedIn: 'root'
})


export class CatalogUtils {

    constructor(
        private catalogService: CatalogService,
      ) { }


    getCountryCatalog(tokn:string, ccode:string, ucode:string): void {
        this.catalogService.getCountries(tokn || '')
            .pipe(retry(2))
            .subscribe(
                (result: any) => {
                    sessionStorage.setItem('countriesList', JSON.stringify(result));
                    localStorage.setItem('countriesList_' + ccode + '_' + ucode, JSON.stringify(result));
                },
                (error: any) => {
                    // this.toastr.error("Please contact the system administrator", "There has been an error!");
                });
    }

    getCatalog(tkn: string, catalogName: string, param: string, sessionVariable: string, ccode:string, ucode:string): CatalogModel[] {
        let catalog: CatalogModel[] = [];
        this.catalogService.getCatalog(tkn || '', catalogName, param)
            .pipe(retry(2))
            .subscribe(
                (result: any) => {
                    sessionStorage.setItem(sessionVariable, JSON.stringify(result));
                    localStorage.setItem(sessionVariable + '_' + ccode + '_' + ucode, JSON.stringify(result));
                    /*if (catalogName == 'ESTADO_BOOKING') {
                        this.bookingStatusList = JSON.parse(sessionStorage.getItem('bookingStatusList') || (localStorage.getItem('bookingStatusList_' + this.ccode + '_' + this.ucode) || ''));
                    }*/
                },
                (error: any) => {
                    // this.toastr.error("Please contact the system administrator", "There has been an error!");
                });
        return catalog;
    }
}